import React from 'react';
import {
    Box,
    Grid,
    Rating,
    Typography,
} from '@mui/material';
import Copy from '../../../../assets/images/copy.svg';
import { useAppSelector } from '../../../../redux/hooks';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Calender from '../../../../assets/images/calendar.svg';
import VideoIcon from '../../../../assets/images/videoCall.svg';
import blankstar from '../../../../assets/images/blankratingsrtar.svg';
import filledstar from '../../../../assets/images/filledratingstar.svg';
import { LikeDislikeAction } from '../../components/LikeDislikeComponent';

interface MeetingDetailsProps {
    meeting: {
        meetingId: string;
        date: string;
        time: string;
        duration: string;
        description: Array<{
            name: string;
            message: string;
            color: string;
        }>;
    };
}

const MeetingDetails: React.FC<MeetingDetailsProps> = ({ meeting }) => {
    const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
    const { meetingId, date, time, duration, description } = meeting;

    return (
        <Box p={2} borderRadius="8px" bgcolor="#FFFFFF" border="1px solid #EFF0F4">
            {/* Meeting Id */}
            <Typography variant="body1" mb={1}>Meeting #{meetingId}</Typography>

            <Grid container spacing={2}>
                {/* Date */}
                <Grid item xs={6} md={4} display="flex" gap="8px" alignItems="center">
                    <img src={Calender} alt="" width="28" height="28" />
                    <Typography variant="h6">{date}</Typography>
                </Grid>

                {/* Time */}
                <Grid item xs={6} md={4} display="flex" gap="8px" alignItems="center">
                    <AccessTimeIcon sx={{ color: '#ABB5BE' }} />
                    <Typography variant="h6">{time}</Typography>
                </Grid>

                {/* Duration */}
                <Grid item xs={6} md={4} display="flex" gap="8px" alignItems="center">
                    <img src={VideoIcon} alt="" width="26" height="26" />
                    <Typography variant="h6">{duration}</Typography>
                </Grid>

                {/* Description */}
                <Grid item xs={12}>
                    <Box display="flex" gap="8px">
                        <img src={Copy} alt="" width="27" height="27" />
                        <Box display={'flex'} flexDirection={'column'} gap={1}>
                            <Box display={'flex'} flexDirection={'column'} key={0}>
                                <Typography variant='subtitle2' color="#E99940">
                                    {description[0]?.name}
                                </Typography>
                                <Typography variant='body1' color="primary">
                                    {description[0]?.message}
                                </Typography>
                            </Box>

                            <Box display={'flex'} flexDirection={'column'} key={1}>
                                <Typography variant='subtitle2' color="#0071A9">
                                    {description[1]?.name}
                                </Typography>
                                <Typography variant='body1' color="primary">
                                    {description[1]?.message}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                {/* Rating */}
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                        <Typography variant='body1'>
                            How satisfied are you with the advice you got?
                        </Typography>

                        <Rating
                            value={3}
                            readOnly={true}
                            className='opacity-50 gap-8'
                            icon={<img src={filledstar} alt="" height={25} width={25} />}
                            emptyIcon={<img src={blankstar} alt="" height={25} width={25} />}
                        />
                    </Box>
                </Grid>

                {/* Like/Dislike Section */}
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                        <Typography variant='body1'>Did you find this conversation helpful?</Typography>
                        <LikeDislikeAction disabled status={'like'} className='opacity-25' />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MeetingDetails;
