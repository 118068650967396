import {
    Box,
    Divider,
    Accordion,
    Typography,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import React, { useState } from 'react';
import MeetingDetails from './MeetingDetails';
import { ExpandMore } from '@mui/icons-material';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import { ChatIcon, VideoCallIcon } from '../../../../utils/Icons';

interface ObjectiveMentorsProps {
    mentorsList: any[];
    onChatClick: (each: any) => void;
    onCalendarClick: (each: any) => void;
}

const ObjectiveMentors: React.FC<ObjectiveMentorsProps> = ({
    mentorsList,
    onChatClick,
    onCalendarClick,
}) => {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null); // Track expanded accordion

    const handleAccordionChange =
        (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedIndex(isExpanded ? index : null); // Only one open accordion
        };

    return (
        <>
            {mentorsList.map((each, index) => (
                <React.Fragment key={each.mid}>
                    {index > 0 && <Divider />}
                    <Accordion
                        expanded={expandedIndex === index} // Expand only if this index matches
                        onChange={handleAccordionChange(index)} // Update expandedIndex on change
                        sx={{
                            '&.Mui-expanded': {
                                margin: '0'
                            }
                        }}
                    >
                        <AccordionSummary
                            sx={{ padding: 0, margin: 0 }}
                            id={`mentor-header-${each.mid}`}
                            aria-controls={`mentor-details-${each.mid}`}
                            expandIcon={<ExpandMore sx={{ color: '#0071A9' }} />}
                        >
                            <Box
                                gap={'8px'}
                                width={'100%'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                {/* Mentor Info */}
                                <Box
                                    gap={1}
                                    flex={1}
                                    display={'flex'}
                                    overflow={'hidden'}
                                    alignItems={'flex-start'}
                                >
                                    <ChatAvatarComponent
                                        width="48px"
                                        height="48px"
                                        type="noStatus"
                                        image={each?.headshot}
                                        firstLetter={each?.displayName?.slice(0, 1)}
                                    />
                                    <Box flex={1} overflow={'hidden'}>
                                        <Typography variant="h5" noWrap>
                                            {each.displayName}
                                        </Typography>
                                        <Typography variant="body1" noWrap>
                                            {each.position || 'Mentor'}
                                        </Typography>
                                    </Box>
                                </Box>

                                {/* Action Icons */}
                                <Box
                                    gap={1}
                                    display={'flex'}
                                    alignItems={'center'}
                                    onClick={(event) => { event.stopPropagation() }}
                                >
                                    <ChatIcon size={21} onClick={() => onChatClick(each)} />
                                    <VideoCallIcon size={21} onClick={() => onCalendarClick(each)} />
                                </Box>
                            </Box>
                        </AccordionSummary>

                        {/* Details Section */}
                        <AccordionDetails sx={{ padding: 0 }}>
                            {each.meetings?.length ? (
                                each.meetings.map((meeting: any, index: number) => (
                                    <Box mb={1.6} key={index}>
                                        <MeetingDetails meeting={meeting} />
                                    </Box>
                                ))
                            ) : (
                                <Typography variant="body1">No meetings scheduled.</Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </React.Fragment>
            ))}
        </>
    );
};

export default ObjectiveMentors;
