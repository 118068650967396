import {
    Box,
    Grid,
    Dialog,
    Select,
    Button,
    Divider,
    MenuItem,
    TextField,
    Typography,
    InputLabel,
    DialogContent,
    DialogActions,
} from '@mui/material';
import {
    lockIcon,
    getStatusColor
} from '../../components/common';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import RecruitNewMentor from './RecruitNewMentor';
import ObjectiveMentors from './ObjectiveMentors';
import { useForm, Controller } from 'react-hook-form';
import { useAppSelector } from '../../../../redux/hooks';
import { mentorsList } from '../../../../utils/dummyLoadingData';
import CommonModalHeader from '../../../../components/DialogUi/CommonModalHeader';

interface ObjectiveModalProps {
    open: boolean;
    title?: string;
    onClose: () => void;
    onSubmit?: (data: any) => Promise<void>;
}

const statusOptions = [
    { value: 'todo', label: 'To Do' },
    { value: 'inprogress', label: 'In Progress' },
    { value: 'completed', label: 'Completed' },
];

const ObjectiveModal: React.FC<ObjectiveModalProps> = ({
    open,
    onClose,
    onSubmit,
    title = "",
}) => {
    const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [recruitModalOpen, setRecruitModalOpen] = useState(false);
    const isMentor = selectedGroupId?.role === 'mentor';
    const isMentee = selectedGroupId?.role === 'mentee';
    const handleRecruitModalOpen = () => setRecruitModalOpen(true);
    const handleRecruitModalClose = () => setRecruitModalOpen(false);
    const { control } = useForm({
        defaultValues: {
            objectiveTitle: '',
            objectiveStatus: 'todo', // Default value maps to 'To Do'
            objectiveDescription: '',
        },
    });

    const [isDescriptionVisible, setDescriptionVisible] = useState(false);
    const [note, setNote] = useState(''); // To manage the note text

    // Handler to toggle the visibility of the description box
    const handleAddNewNoteClick = () => {
        setDescriptionVisible(true);
    };

    // Handler to update the note
    const handleNoteChange = (event: any) => {
        setNote(event.target.value);
    };

    // Handler to close the description box and display the note
    const handleCloseDescriptionBox = () => {
        setDescriptionVisible(false);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                {/* Modal Header */}
                <CommonModalHeader
                    title={title}
                    handleCloseModal={onClose}
                />

                {/* Modal Content */}
                <DialogContent className='p-0'>
                    <Grid container spacing={2} p={2} pb={isMentor ? 0 : 2}>
                        {/* Objective Title */}
                        <Grid item xs={12}>
                            <Box display={'flex'} gap={1}>
                                <InputLabel htmlFor="objective-title">Objective Title {isMentor && lockIcon({})}</InputLabel>
                            </Box>
                            <Controller
                                name="objectiveTitle"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        disabled={isMentor}
                                        id="objective-title"
                                        variant="outlined"
                                        placeholder="Enter Objective Title"
                                    />
                                )}
                            />
                        </Grid>

                        {/* Objective Description */}
                        <Grid item xs={12}>
                            <Box display={'flex'} gap={.5}>
                                <InputLabel htmlFor="objective-description">Objective Description {isMentor && lockIcon({})}</InputLabel>
                            </Box>
                            <Controller
                                name="objectiveDescription"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        multiline
                                        rows={4}
                                        fullWidth
                                        {...field}
                                        variant="outlined"
                                        disabled={isMentor}
                                        id="objective-description"
                                        placeholder="Enter Objective Description"
                                    />
                                )}
                            />
                        </Grid>

                        {/* Objective Status */}
                        <Grid item xs={12} className={isMentor ? "pointerEvents-none" : ''}>
                            <Box display={'flex'}>
                                <InputLabel htmlFor="objective-status">Objective Status {isMentor && lockIcon({})}</InputLabel>
                            </Box>
                            <Controller
                                name="objectiveStatus"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        fullWidth
                                        {...field}
                                        id="objective-status"
                                        sx={{
                                            backgroundColor: getStatusColor(field?.value as StatusType),
                                            color: '#FFFFFF',
                                            '& .MuiSelect-icon': {
                                                color: '#FFFFFF', // Change the icon color to white
                                                display: isMentor ? 'none' : 'unset', // Hide icon if isMentor is true
                                            },
                                        }}
                                    >
                                        {statusOptions?.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </Grid>

                        {/* Objective Mentors */}
                        <Grid item xs={12}>
                            <Typography>Objective Mentors</Typography>
                            {/* If no mentors, show placeholder text */}
                            {mentorsList?.length === 0 ? (
                                <Typography textAlign={'center'}>No mentors available</Typography>
                            ) : (
                                <ObjectiveMentors
                                    mentorsList={mentorsList}
                                    onChatClick={() => { }}
                                    onCalendarClick={() => { }}
                                />
                            )}
                            {isMentee && (
                                <Grid container justifyContent="center" alignItems="center">
                                    <Button
                                        color='info'
                                        variant="contained"
                                        className='w-50p fs-14'
                                        onClick={handleRecruitModalOpen}
                                    >
                                        Recruit New Mentor
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container spacing={2} p={2}>
                        {/* Objective Notes */}
                        <Grid item xs={12}>

                            <Typography >Objective Notes</Typography>
                            {/* If description box is visible, show TextField for entering the note */}
                            {isDescriptionVisible ? (
                                <Box mt={2}>
                                    <TextField
                                        multiline
                                        rows={4}
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Enter Objective Description"
                                        value={note}
                                        onChange={handleNoteChange}
                                        onBlur={handleCloseDescriptionBox}
                                    />
                                </Box>
                            ) : (
                                <Box mt={1}>
                                    <Typography variant="h6">November 12, 2024 </Typography>
                                    <Typography>
                                        Had a great call with Mary today. She was able to share some sample business case templates with me and explain how to use them. My next step is to create a first draft and then schedule a follow up session to review.
                                    </Typography>
                                </Box>
                            )}

                            {!isDescriptionVisible && isMentee && (
                                <Grid container justifyContent="center" alignItems="center">
                                    <Button
                                        color='info'
                                        variant="contained"
                                        className='w-50p fs-14 mt-20'
                                        onClick={handleAddNewNoteClick}
                                    >
                                        Add New Note
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>

                {/* Modal Actions */}
                {isMentee && (
                    <DialogActions>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button onClick={onClose} variant="outlined" fullWidth>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <LoadingButton
                                    onClick={() => { }}
                                    variant="contained"
                                    loading={submitLoader}
                                    fullWidth
                                >
                                    Save
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </DialogActions>
                )}
            </Dialog>

            {/* Recruit New Mentor Modal */}
            <RecruitNewMentor
                open={recruitModalOpen}
                onClose={handleRecruitModalClose}
                mentorsList={mentorsList}
                onProfileClick={(mid) => console.log(`Profile clicked: ${mid}`)}
                onChatClick={(mentor) => console.log('Chat clicked', mentor)}
                onCalendarClick={(mentor) => console.log('Calendar clicked', mentor)}
            />
        </>
    );
};

export default ObjectiveModal;
