import React from "react";
import { Box } from "@mui/material";
import ChatAvatarComponent from "../chat/ChatAvatarComponent";

interface Peer {
    avatar: string;
    firstName: string;
}

interface MyPeersProps {
    peersList: Peer[];
    loading: boolean;
}

const MyPeers: React.FC<MyPeersProps> = ({ peersList, loading }) => {
    return (
        <Box
            display="flex"
            // gridTemplateColumns="repeat(auto-fit, minmax(48px, 1fr))"
            gap="10px"
            // justifyContent="center"
            // alignItems="center"
            maxHeight={'calc(2 * 48px + 10px)'}
            flexWrap={'wrap'}
            overflow="hidden"
        >
            {peersList?.map((peer, index) => (
                <ChatAvatarComponent
                    key={index}
                    image={peer.avatar}
                    type="noStatus"
                    firstLetter={peer.firstName.charAt(0)}
                    width="48px"
                    height="48px"
                    cursor="pointer"
                    loading={loading}
                />
            ))}
        </Box>
    );
};

export default MyPeers;
