import {
    Box,
    Dialog,
    Divider,
    Typography,
    DialogContent,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import React, { useState } from 'react';
import MentorDetails from './MentorDetails';
import { ExpandMore } from '@mui/icons-material';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import { ChatIcon, VideoCallIcon } from '../../../../utils/Icons';
import CommonModalHeader from '../../../../components/DialogUi/CommonModalHeader';

interface Mentor {
    mid: string;
    displayName: string;
    headshot: string;
    position?: string;
}

interface RecruitNewMentorModalProps {
    open: boolean;
    onClose: () => void;
    mentorsList: Mentor[];
    onProfileClick: (mid: string) => void;
    onChatClick: (mentor: Mentor) => void;
    onCalendarClick: (mentor: Mentor) => void;
}

const RecruitNewMentor: React.FC<RecruitNewMentorModalProps> = ({
    open,
    onClose,
    mentorsList,
    onProfileClick,
    onChatClick,
    onCalendarClick,
}) => {
    const [expandedIndex, setExpandedIndex] = useState<number>(0); // Default to the first accordion

    const handleAccordionChange = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedIndex(isExpanded ? index : -1); // Collapse all if not expanded
    };

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={onClose}
            PaperProps={{ sx: { height: '100%' } }}
        >
            {/* Modal Header */}
            <CommonModalHeader
                title={'Recruit New Mentor'}
                handleCloseModal={onClose}
            />
            {/* Modal Content */}
            <DialogContent>
                <Typography variant="h6">
                    Your Suggested Mentors
                </Typography>
                {mentorsList.map((mentor, index) => (
                    <React.Fragment key={mentor.mid}>
                        {/* Divider */}
                        {index > 0 && <Divider />}
                        <Accordion
                            sx={{
                                '&.Mui-expanded': {
                                    margin: '0'
                                }
                            }}
                            expanded={expandedIndex === index}
                            onChange={handleAccordionChange(index)}
                        >
                            <AccordionSummary
                                sx={{ padding: 0, margin: 0 }}
                                id={`mentor-header-${mentor.mid}`}
                                aria-controls={`mentor-details-${mentor.mid}`}
                                expandIcon={<ExpandMore sx={{ color: '#0071A9' }} />}
                            >
                                <Box
                                    gap="8px"
                                    width="100%"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    {/* Mentor Info */}
                                    <Box>
                                        <Box
                                            flex={1}
                                            gap="10px"
                                            display="flex"
                                            overflow="hidden"
                                            alignItems="center"
                                            className="cursor-pointer"
                                            onClick={() => onProfileClick(mentor.mid)}
                                        >
                                            <ChatAvatarComponent
                                                width="48px"
                                                height="48px"
                                                type="noStatus"
                                                image={mentor?.headshot}
                                                firstLetter={mentor?.displayName?.slice(0, 1)}
                                            />
                                            <Box flex={1} overflow={"hidden"}>
                                                <Typography variant="h5" noWrap>
                                                    {mentor.displayName}
                                                </Typography>
                                                <Typography variant="body1" noWrap>
                                                    {mentor.position || 'Mentor'}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Typography variant="body1" noWrap ml={6}>
                                            I can help you with...
                                        </Typography>
                                    </Box>
                                    {/* Action Icons */}
                                    <Box
                                        gap={1}
                                        display={"flex"}
                                        alignItems={"center"}
                                        onClick={(event) => { event.stopPropagation() }}
                                    >
                                        <ChatIcon
                                            size={21}
                                            onClick={() => { onChatClick(mentor) }}
                                        />
                                        <VideoCallIcon
                                            size={21}
                                            onClick={() => { onCalendarClick(mentor) }}
                                        />
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            {/* Mentor Details */}
                            <AccordionDetails
                                sx={{
                                    padding: '0',
                                    paddingBottom: index === mentorsList.length - 1 ? '0' : '20px',
                                }}
                            >
                                <MentorDetails mentorId={mentor.mid} />
                            </AccordionDetails>
                        </Accordion>
                    </React.Fragment>
                ))}
            </DialogContent>
        </Dialog>
    );
};

export default RecruitNewMentor;
