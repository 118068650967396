import { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  Autocomplete,
  DialogContent,
  DialogActions,
  FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { API } from '../../../../api';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { popupCountActions } from '../../../../redux/slices/poupsCountsSlice';
import { getUserDetails } from '../../../../utils/orgName';
import useProfileDetails from '../../../../hooks/useProfileDetails';
import { userActions } from '../../../../redux/slices/user-profile/userProfieSlice';

const LearningObjectives = () => {
  const { ftue, number } = useProfileDetails();
  const dispatch = useAppDispatch();
  const { orgId, userId } = getUserDetails();
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const isMentor = selectedGroupId?.role === 'mentor';

  const popuplength = useAppSelector((state) => state.popupsCount.popuplength);
  const count = useAppSelector((state) => state.popupsCount.count);
  const nextPopUpOrNot = popuplength - count;

  const getLocalStorageKey = (key: string) => `learningObjectives_${userId}_${key}`;
  const getInitialState = (key: string, defaultValue: any) => {
    const localStorageKey = getLocalStorageKey(key);
    const savedValue = localStorage.getItem(localStorageKey);
    return savedValue ? JSON.parse(savedValue) : defaultValue;
  };

  const [learningObjectiveCount, setLearningObjectiveCount] = useState(getInitialState('learningObjectiveCount', 3));
  const [selectedObjectives, setSelectedObjectives] = useState<string[]>(getInitialState('selectedObjectives', []));
  const [errors, setErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ftueLoading, setFtueLoading] = useState(false);
  const [objectives, setObjectives] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    localStorage.setItem(getLocalStorageKey('selectedObjectives'), JSON.stringify(selectedObjectives));
  }, [selectedObjectives, userId]);

  useEffect(() => {
    const fetchLearningObjectives = async () => {
      try {
        const response = await API.getUserLearningObjectives({ orgId });
        if (response?.data?.data && Array.isArray(response.data.data)) {
          setObjectives(response.data.data);
        } else {
          setObjectives([]);
        }
      } catch (error) {
        setObjectives([]);
      }
    };
    fetchLearningObjectives();
  }, [orgId]);

  useEffect(() => {
    if (number) {
      setLearningObjectiveCount(number);
    }
  }, [number]);

  const handleInputChange = (index: number, value: string) => {
    const updatedObjectives = [...selectedObjectives];
    while (updatedObjectives.length <= index) {
      updatedObjectives.push('');
    }
    updatedObjectives[index] = value;

    const cleanObjectives = updatedObjectives.filter((obj) => obj !== '');
    setSelectedObjectives(cleanObjectives);
  };

  const checkErrors = () => {
    const validationErrors = Array.from({ length: learningObjectiveCount }).map((_, index) =>
      !selectedObjectives[index]
        ? `${isMentor ? 'Area of expertise' : 'Learning Objective'} ${index + 1} is required`
        : ''
    );
    setErrors(validationErrors);
    return validationErrors.some((error) => error !== '');
  };

  const updateFtue = async () => {
    setFtueLoading(true);
    try {
      const response = await API.updateUserFtue({ ftue: false, userId });
      if (response.status === 200 && response.statusText === 'OK') {
        localStorage.removeItem(getLocalStorageKey('selectedObjectives'));
        localStorage.removeItem(getLocalStorageKey('learningObjectiveCount'));
        dispatch(userActions.userFtueUpdate(0));
        setFtueLoading(false);
      }
    } catch (e) {
      toast.error('Failed to update user status');
      setFtueLoading(false);
    }
  };

  const onSubmit = async () => {
    if (checkErrors()) {
      toast.error('Please fill in all required fields');
      return;
    }

    setIsSubmitting(true);

    try {
      const objectivesToSave = selectedObjectives
        .slice(0, learningObjectiveCount)
        .filter((objective) => objective !== '');

      const response = await API.saveLearningObjectives({
        learningObjectives: objectivesToSave
      });

      if (response.status === 200) {
        localStorage.setItem(getLocalStorageKey('learningObjectiveCount'), JSON.stringify(learningObjectiveCount));
        if (ftue && nextPopUpOrNot !== 1) {
          dispatch(popupCountActions.increment(0));
        }
        if (nextPopUpOrNot === 1) {
          await updateFtue();
        }
      }
    } catch (error) {
      console.error('Error saving learning objectives:', error);
      toast.error('An error occurred while saving');
    } finally {
      setIsSubmitting(false);
    }
  };

  const getFilteredOptions = (index: number) => {
    const filteredOptions = objectives.filter(
      (option) => !selectedObjectives.includes(option) || selectedObjectives[index] === option
    );
    if (
      inputValue &&
      inputValue.trim() !== '' &&
      !filteredOptions.includes(inputValue) &&
      !objectives.includes(inputValue)
    ) {
      return [...filteredOptions, `Add "${inputValue}"`];
    }
    return filteredOptions;
  };

  const handleOptionSelection = (index: number, newValue: string | null) => {
    if (!newValue) {
      handleInputChange(index, '');
      return;
    }
    if (newValue.startsWith('Add "') && newValue.endsWith('"')) {
      const customValue = newValue.slice(5, -1).trim();
      if (customValue !== '') {
        if (!objectives.includes(customValue)) {
          setObjectives((prev) => [...prev, customValue]);
        }
        handleInputChange(index, customValue);
      }
    } else {
      handleInputChange(index, newValue);
    }
    setInputValue('');
  };
  const getDialogActions = () => {
    return (
      <DialogActions>
        {ftue && (
          <LoadingButton
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={() => {
              localStorage.setItem(
                getLocalStorageKey('learningObjectiveCount'),
                JSON.stringify(learningObjectiveCount)
              );
              dispatch(popupCountActions.decrement(0));
            }}
          >
            Back
          </LoadingButton>
        )}

        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          loading={isSubmitting || ftueLoading}
          onClick={onSubmit}
        >
          {nextPopUpOrNot === 1 ? 'Finish' : nextPopUpOrNot > 1 ? 'Next' : 'Save'}
        </LoadingButton>
      </DialogActions>
    );
  };
  return (
    <>
      <DialogContent>
        <Typography variant="subtitle1" pb={2}>
         {isMentor ? 'Area of expertise' : 'Objectives will appear on your profile so that suitable mentors may reach out to you'} 
        </Typography>
        <Grid container spacing={2}>
          {Array.from({ length: learningObjectiveCount }).map((_, index) => (
            <Grid item xs={12} key={index}>
              <InputLabel>{`${isMentor ? 'Area of expertise' : 'Learning Objective'} ${index + 1} *`}</InputLabel>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  size="small"
                  id={`learning-objective-${index}`}
                  value={selectedObjectives[index] || ''}
                  options={getFilteredOptions(index)}
                  onChange={(_, newValue) => handleOptionSelection(index, newValue)}
                  onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      placeholder={`Select ${isMentor ? 'area of expertise' : 'learning Objective'} ${index + 1}`}
                      error={!!errors[index]}
                    />
                  )}
                />
                <FormHelperText error>{errors[index]}</FormHelperText>
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      {getDialogActions()}
    </>
  );
};

export default LearningObjectives;
