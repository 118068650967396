/** Generate language data */
const generateLanguageData = (count: number): LanguageData[] => {
    /** Languages sorted by the most spoken (number of speakers) */
    const languages = ['English', 'Mandarin Chinese', 'Hindi', 'Spanish', 'French', 'Arabic', 'Bengali', 'Russian', 'Portuguese', 'Urdu', 'Indonesian', 'German', 'Japanese', 'Swahili', 'Marathi', 'Telugu', 'Turkish', 'Korean', 'Tamil', 'Vietnamese'];
    /** Realistic proficiency levels based on language learning/usage data */
    const proficiencies = [
        'Fluent', // Fluent or native-level proficiency
        'Intermediate', // Able to hold conversations, but with some errors
        'Basic', // Limited knowledge, understanding of basic phrases
        'Native', // Native speaker or near-native proficiency
        'Conversational' // Ability to converse on common topics, but with gaps
    ];

    return Array.from({ length: count }, (_, index) => ({
        language: languages[index % languages.length],
        proficiency: proficiencies[index % proficiencies.length],
    }));
};

/** Use how many you need */
export const languageData: LanguageData[] = generateLanguageData(2);