import React from 'react';
import { Box } from '@mui/material';
import VideoPlayer from './VideoPlayer';
import MySkeleton from '../../../components/Skeleton';
import ChatAvatarComponent from '../tabs/chat/ChatAvatarComponent';
import useResponsiveScreenSwitch from '../../../utils/useResponsiveScreenSwitch';

interface Props {
  data?: {
    headshot?: string;
    displayName?: string;
    videoIntro?: string;
  };
  loading?: boolean;
  py?: number;
}

const UserDigitalIntro: React.FC<Props> = ({ loading, data, py = 2 }) => {
  /** For responsive hook */
  const { forMobile } = useResponsiveScreenSwitch();
  return (
    <Box py={py}>
      {loading ? (
        <MySkeleton variant="rounded" height={310} width={'100%'} />
      ) : data?.videoIntro ? (
        <VideoPlayer
          src={data?.videoIntro}
          width={forMobile ? '100%' : '560px'}
          height={forMobile ? '150px' : '310px'}
        />
      ) : (
        <ChatAvatarComponent
          type="noStatus"
          fontSize={'30px'}
          loading={loading}
          image={data?.headshot}
          width={forMobile ? '98%' : '560px'}
          height={forMobile ? '150px' : '310px'}
          firstLetter={(data?.displayName || '').slice(0, 1)}
        />
      )}
    </Box>
  );
};

export default UserDigitalIntro;
