/** Generates Dummy data */
const generateDummyData = (count: number, prefix = 'Option', structured = false) => {
    return Array.from({ length: count }, (_, index) =>
        structured
            ? { value: `${prefix.toLowerCase()}${index + 1}`, label: `${prefix} ${index + 1}` }
            : `${prefix} ${index + 1}`
    );
};

/** Dummy data for label */
export const dummyTopics = generateDummyData(4, 'Topic');
/** Dummy data for Value & label */
export const dummySelectData = generateDummyData(4, 'Option', true);

/** Generateing dummy Leaderboard data */
export const generateDummyLeaderboardData = (count: number = 10) => {
    const displayName = "Jane Smith Jane Smith Jane Smith Jane Smith";
    const headshot = "https://via.placeholder.com/150";
    const mockData = [];

    for (let i = 1; i <= count; i++) {
        const randomGoalProgress = Math.floor(Math.random() * 101);

        mockData.push({
            id: i.toString(),
            displayName,
            headshot,
            goalProgress: randomGoalProgress,
        });
    }

    return mockData;
};

/** Dummy myMentors list for loading */
const generateDummyMentors = (count: number) => {
    const dummyMentors = [];
    for (let i = 1; i <= count; i++) {
        dummyMentors.push({
            displayName: `Loading Mentor ${i}`,
            requestFlag: true
        });
    }
    return dummyMentors;
};

/** Use the function to generate dummy data with a specific count */
export const dummyMyMentorsList = generateDummyMentors(2);

/** Dummy and Dosen Social Data */
export const dummySocialData = {
    facebook: 'https://www.facebook.com/dosen.io/',
    instagram: 'https://www.instagram.com/dosen.io',
    twitter: 'https://twitter.com/dosen',
    linkedIn: 'https://www.linkedin.com/company/dosen/',
    youtube: 'https://www.youtube.com'
};

/** Generate Dummy Work and Edu Data */
const generateDummyWorkAndEduData = (count: number) => {
    const companies = ['Dosen, LLC', 'ABC Company', 'Tech Corp', 'Innovate Inc', 'CodeWorks'];
    const roles = ['Developer', 'Engineer', 'Designer', 'Analyst'];
    const titles = ['Software Engineer', 'Frontend Developer', 'UI/UX Designer', 'Data Analyst'];
    const universities = ['Temple University', 'Harvard University', 'MIT', 'Stanford University', 'Oxford University'];
    const majors = ['BSC, Mathematics & Statistics', 'Master in Design', 'Computer Science', 'Electrical Engineering'];
    const minors = ['Mathematics', 'Physics', 'Statistics', 'Chemistry', 'Biology'];
    const industries = ['IT', 'Finance', 'Education', 'Healthcare', 'Retail'];
    const departments = ['Computer Science', 'Mathematics', 'Design', 'Engineering'];

    return Array.from({ length: count }, (_, index) => ({
        company_name: companies[index % companies.length],
        roleType: [roles[index % roles.length]],
        title: titles[index % titles.length],
        start_date: `${2010 + index}`,
        end_date: index % 2 === 0 ? `${2015 + index}` : 'Present',
        industry: industries[index % industries.length],
        currentlyWorking: index % 2 !== 0,
        university: universities[index % universities.length],
        major: majors[index % majors.length],
        minor: [minors[index % minors.length], minors[(index + 1) % minors.length]],
        graduation_date: `${2016 + index}`,
        department: departments[index % departments.length],
    }));
};

/** Use Dummy Work and Edu Data */
export const dummyWorkAndEduData = generateDummyWorkAndEduData(2);


/** Dummy avatar created */
const createDummyAvatarArray = (useImage = false) => {
    const letters = ['D', 'O', 'S', 'E', 'N', 'I', 'N', 'C'];

    return Array.from({ length: letters.length }, (_, index) => ({
        avatar: useImage ? `https://classic.dosen.io/images/litters-images/${letters[index].toLowerCase()}.png` : '',
        firstName: letters[index]
    }));
};

/** If avatar array is null and loader is true, use this as a dummy avatar */
export const dummyAvatar = createDummyAvatarArray();
/** Use how many Dummy avatar with image you need */
export const dummyAvatarImage = createDummyAvatarArray(true);

/** Generate Dummy Task card Data */
const generateDummyTaskData = (numTasks: number) => {
    const statuses = ["todo", "inprogress", "completed"];
    const titles = [
        "",
        "Enhancing Marketing Strategies",
        "Developing Leadership Skills",
        "Building a Collaborative Team",
        "Improving Operational Efficiency",
    ];

    const descriptions = [
        "Gain expertise in analyzing financial.",
        "Develop creative strategies to effectively target and expand the customer base statements to make informed business decisions.",
        "Enhance your leadership abilities to inspire and.",
        "Learn methods to foster teamwork and create a productive work environment  guide your team effectively.",
        "Streamline operations to increase productivity and reduce costs effectively.",
    ];

    const randomStatus = () => statuses[Math.floor(Math.random() * statuses.length)];
    const randomTitle = () => titles[Math.floor(Math.random() * titles.length)];
    const randomDescription = () => descriptions[Math.floor(Math.random() * descriptions.length)];

    const generateTask = (id: number) => {
        return {
            id: id.toString(),
            objectiveNumber: id,
            totalObjectives: numTasks,
            title: randomTitle(),
            description: randomDescription(),
            status: randomStatus(),
            noteCount: Math.floor(Math.random() * 10), // Random number of notes between 0 and 9
            videoCallCount: Math.floor(Math.random() * 5), // Random number of video calls between 0 and 4
        };
    };

    // Generate an array of tasks
    const tasks = [];
    for (let i = 1; i <= numTasks; i++) {
        tasks.push(generateTask(i));
    }

    return tasks;
};
/** Use how many dummy card you need */
export const dummyTaskData = generateDummyTaskData(5);

/** Generate Dummy article row */
const generateArticleData = (count: number): Article[] => {
    const articleNames = [
        'Lorem ipsum dolor sit amet consectetur adipiscing elit',
        'Lorem ipsum dolor sit amet'
    ];
    const authors = ['Ronan Wall', 'Puskar Koley'];
    const coverImageUrl = 'https://via.placeholder.com/150';
    const readTimes = ['5 min', '8 min', '10 min'];
    const locations = ['default', 'USA', 'UK', 'IND'];

    return Array.from({ length: count }).map((_, index) => ({
        id: (index + 1).toString(),
        coverImageUrl,
        articleName: articleNames[index % articleNames.length],
        readTime: readTimes[index % readTimes.length],
        author: authors[index % authors.length],
        location: locations[index % locations.length],
    }));
};
/** Use how many articles you need */
export const dummyArticleRowData: Article[] = generateArticleData(5);

/** Dummy Objective Modal Mentors */
export const mentorsList = [
    {
        mid: '1',
        displayName: 'John Doe',
        headshot: '',
        position: 'Software Engineer',
        meetings: [
            {
                meetingId: '1',
                date: '04/24/2023',
                time: '12:00 pm (EST)',
                duration: '15 Min',
                description: [
                    {
                        name: 'Alex Martin',
                        message: 'I want to ask about Design in Master in our College.',
                    },
                    {
                        name: 'Lincoln Curtis',
                        message: 'I want to ask about Design in Master in our College.',
                    },
                ],
            },
            {
                meetingId: '2',
                date: '05/05/2023',
                time: '3:00 pm (EST)',
                duration: '30 Min',
                description: [
                    {
                        name: 'Sarah Blake',
                        message: 'I need advice on web development.',
                    },
                    {
                        name: 'Lincoln Curtis',
                        message: 'I want to ask about Design in Master in our College.',
                    },
                ],
            },
        ],
    },
    {
        mid: '2',
        displayName: 'Lincoln Doe',
        headshot: '',
        position: 'Software Engineer',
        meetings: [
            {
                meetingId: '1',
                date: '04/24/2023',
                time: '12:00 pm (EST)',
                duration: '15 Min',
                ratings: 4,
                description: [
                    {
                        name: 'Alex Martin',
                        message: 'I want to ask about Design in Master in our College.',
                    },
                    {
                        name: 'Lincoln Curtis',
                        message: 'I want to ask about Design in Master in our College.',
                    },
                ],
            },
            {
                meetingId: '2',
                date: '05/05/2023',
                time: '3:00 pm (EST)',
                duration: '30 Min',
                ratings: 5,
                description: [
                    {
                        name: 'Sarah Blake',
                        message: 'I need advice on web development.',
                    },
                    {
                        name: 'Lincoln Curtis',
                        message: 'I want to ask about Design in Master in our College.',
                    },
                ],
            },
        ],
    },
];