export const useModule = () => {
    const userModule: 'masterclass' | 'mentorship' | 'hybrid' = getUserModule(); // Explicit type

    function getUserModule(): 'masterclass' | 'mentorship' | 'hybrid' {
      // Logic to determine the user module
      return 'hybrid'; // Replace with actual logic
    }
  
    return {
      isMasterclass: userModule === 'masterclass',
      isMentorship: userModule === 'mentorship',
      isHybrid: userModule === 'hybrid',
    };
  };
  