import React from 'react';
import _ from 'lodash';
import MyMentorCard from './MentorCard';
import MySkeleton from '../../../../components/Skeleton';
import { Box, Stack, Divider, Typography } from '@mui/material';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';

interface MyMentorCardProps {
    myMentorList: any;
    loading?: boolean;
    myRequestedMentorList?: any;
    doReload?: () => void;
}

const MyMentorSection: React.FC<MyMentorCardProps> = ({
    loading,
    doReload,
    myMentorList,
    myRequestedMentorList,
}: MyMentorCardProps) => {
    const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
    const mentorCardCount = myMentorList?.length || 0;
    const requestedMentorCount = myRequestedMentorList?.length || 0;
    const totalMentorsFound = mentorCardCount > 0 || requestedMentorCount > 0;
    const totalMentorsCount = mentorCardCount + requestedMentorCount;
    let mentorsWithRequestFlag: any[];
    if (Array.isArray(myRequestedMentorList) && myRequestedMentorList.length > 0) {
        mentorsWithRequestFlag = myRequestedMentorList.map((mentor: any) => ({
            ...mentor,
            requestFlag: true
        }));
    } else {
        mentorsWithRequestFlag = [];
    }
    const totalMentors = [...mentorsWithRequestFlag, ...(myMentorList || [])];

    /** If this User no have any mentor  */
    function MentorNotFound() {
        return (
            <Stack direction="column" justifyContent="center" alignItems="center" px={2}>
                {loading ? (
                    <Box width={'100%'} textAlign={'center'} pt={{ sm: 3 }}>
                        <MySkeleton width={'80%'} className="mx-auto" />
                        <MySkeleton width={'80%'} className="mx-auto" />
                    </Box>
                ) : (
                    <Typography textAlign="center" pt={{ sm: 3 }}>
                        You have not been matched with anyone yet. Contact your program administrator to be matched.
                    </Typography>
                )}
            </Stack>
        );
    }

    return (
        <>
            {!totalMentorsFound ? (
                <MentorNotFound />
            ) : (
                <Box pt={2} flex={1}>
                    <Box overflow={'auto'} pb={1.5} height={'100%'}>
                        <Stack
                            direction={forMobile ? 'column' : 'row'}
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={2}
                            height={'100%'}
                        >
                            {totalMentorsCount &&
                                _.uniqBy(totalMentors, 'displayName').map((each, index) => {
                                    return index < 5 ? (
                                            <MyMentorCard
                                                key={index}
                                                mentor={each}
                                                loading={loading}
                                                doReload={doReload}
                                                myRequestedMentorList={myRequestedMentorList}
                                            />
                                        ) : null;
                                })}
                        </Stack>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default MyMentorSection;
