import React, { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import handthumbsup from '../../../assets/images/hand-thumbs-up.svg';
import handthumbsdown from '../../../assets/images/hand-thumbs-down.svg';
import handthumbsupfill from '../../../assets/images/hand-thumbs-up-fill.svg';
import handthumbsdownfill from '../../../assets/images/hand-thumbs-down-fill.svg';

interface LikeActionBtnProps {
    disabled?: boolean;
    like?: boolean;
    onClick?: () => void;
}

export const LikeActionBtn: React.FC<LikeActionBtnProps> = ({ disabled = false, like = false, onClick }) => {
    return (
        <Tooltip title="Like">
            <IconButton
                onClick={onClick}
                disabled={disabled}
            >
                <img src={like ? handthumbsupfill : handthumbsup} alt="hand-thumbs-up" />
            </IconButton>
        </Tooltip>
    );
};

interface DislikeActionBtnProps {
    disabled?: boolean;
    dislike?: boolean;
    onClick?: () => void;
}

export const DislikeActionBtn: React.FC<DislikeActionBtnProps> = ({ disabled = false, dislike = false, onClick }) => {
    return (
        <Tooltip title="Dislike">
            <IconButton
                onClick={onClick}
                disabled={disabled}
            >
                <img src={dislike ? handthumbsdownfill : handthumbsdown} alt="hand-thumbs-down" />
            </IconButton>
        </Tooltip>
    );
};

interface LikeDislikeActionProps {
    disabled?: boolean;
    className?: string;
    status?: 'like' | 'dislike' | '';
}

export const LikeDislikeAction: React.FC<LikeDislikeActionProps> = ({ disabled = false, status = '', className }) => {
    const [likeStatus, setLikeStatus] = useState<'like' | 'dislike' | ''>(status);
    const [dislikeStatus, setDislikeStatus] = useState<'like' | 'dislike' | ''>(status);

    const handleLikeClick = () => {
        if (likeStatus === 'like') {
            setLikeStatus('');
        } else {
            setLikeStatus('like');
            setDislikeStatus(''); // Reset dislike status when like is selected
        }
    };

    const handleDislikeClick = () => {
        if (dislikeStatus === 'dislike') {
            setDislikeStatus('');
        } else {
            setDislikeStatus('dislike');
            setLikeStatus(''); // Reset like status when dislike is selected
        }
    };

    return (
        <Box display="flex" className={className}>
            <LikeActionBtn
                disabled={disabled}
                like={likeStatus === 'like'}
                onClick={handleLikeClick}
            />
            <DislikeActionBtn
                disabled={disabled}
                dislike={dislikeStatus === 'dislike'}
                onClick={handleDislikeClick}
            />
        </Box>
    );
};
