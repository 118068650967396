import React from 'react';
import {
  Box,
  Divider,
  Typography,
  IconButton
} from '@mui/material';
import { EditIcon } from '../../../utils/Icons';
import MySkeleton from '../../../components/Skeleton';
import { languageData } from '../../../utils/languageData';

const YourLanguage: React.FC<IYourLanguage> = ({
  editing = false,
  divider = false,
  loading = false,
  data = languageData
}) => {
  return (
    <Box pb={divider ? 0 : 2}>
      <Box
        px={divider ? 2 : 0}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography variant="h5" py={2}>
          {loading ? (<MySkeleton variant="text" height={16} width={150} />) : 'Language you speak'}
        </Typography>
        {/* Edit icon */}
        {editing &&
          <IconButton size='small'>
            <EditIcon />
          </IconButton>
        }
      </Box>
      {/* Divider for profile page */}
      {divider && <Divider />}

      <Box
        gap="8px"
        p={divider ? 2 : 0}
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)" // Creates 5 equal-width columns
      >
        {data.length > 0 ? (
          data.map((item, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Typography variant="h6">
                {loading ? (<MySkeleton variant="text" height={16} width={100} />) : item.language}
              </Typography>
              <Typography variant="body1">
                {loading ? (<MySkeleton variant="text" height={16} width={100} />) : item.proficiency}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography>No language added</Typography>
        )}
      </Box>
    </Box>
  );
};

export default YourLanguage;
