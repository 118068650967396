import { colors } from '@mui/material';
import { createTheme } from '@mui/material/styles';

export const appColors = {
  white: '#FFFFFF',
  black: '#000000',
  gray1: '#EFF0F4',
  gray2: '#F9FAFC',
  orange: '#DF6431',
  gray3: '#ABB5BE',
  lightGreen: '#8BB770',
  gray4: '#68717A',
  black1: '#262738',
  blue1: '#0071A9', //0082B6
  blue2: '#1a8bed',
  dosenNavyBlue: '#262738',
  golden: '#FEB272',

  /** Text */
  primary: '#152536',
  secondary: '#68717A'
};

/** Theme */
export const theme = createTheme({
  palette: {
    primary: {
      main: '#152536',
      dark: '#FFFFFF'
    },
    secondary: {
      main: '#68717A',
      light: '#ABB5BE',
    },
    warning: {
      main: '#E99940' // Y
    },
    error: {
      main: '#DC3545', // Red
    },
    info: {
      main: '#0071A9', //Blue
    },
    success: {
      main: '#28A745', // Green
    },
    grey: {
      50: '#EFF0F4',
      100: '#F9FAFC',
      200: '#999999',
    },
  },

  spacing: 10, //10*1. 10*2 ...... 10*n

  // breakpoints: {
  //   values: {
  //     xs: 576,
  //     sm: 768,
  //     md: 992,
  //     lg: 1200,
  //     xl: 1400,
  //   },
  // },

  typography: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontSize: 24,
      fontWeight: 700,
      color: '#152536'
    },
    h2: {
      fontSize: 22,
      fontWeight: 700,
      color: '#152536'
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
      color: '#152536'
    },
    h4: {
      fontSize: 18,
      fontWeight: 700,
      color: '#152536'
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
      color: '#152536'
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
      color: '#152536'
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      color: '#68717A'
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      color: '#68717A'
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
    },
    caption: {
      fontSize: 12,
      fontWeight: 600,
    },
    button: {
      fontSize: 14,
      fontWeight: 700,
    },
    overline: {
      fontSize: 10,
      fontWeight: 400,
    },
  },

  shape: {
    borderRadius: 8,
  },

  components: {
    /** Inputs */
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#EFF0F4",
          },
          "&.Mui-disabled": {
            borderColor: "#EFF0F4 !important",
          }
        },
        notchedOutline: {
          borderColor: "#EFF0F4",
        },
      }
    },
    MuiFormGroup:{
      styleOverrides: {
        root:{
          row: {
            alignItems:'center'
          }
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          '& .Mui-disabled': {
            background: "#EFF0F4 !important",
            borderRadius: "8px !important",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "8px !important",
          '& .Mui-disabled': {
            background: "#EFF0F4 !important",
            borderRadius: "8px !important",
          },
          '& input': {
            fontSize: "14px !important",
            fontFamily: "Open Sans !important",
            fontWeight: 600,
            background: "#FFFFFF !important",
            '&::placeholder': {
              fontSize: "14px !important",
              fontWeight: "400 !important",
              color: '#ABB5BE',
            },
          },
          '& textarea': {
            fontSize: "14px !important",
            fontFamily: "Open Sans !important",
            fontWeight: 600,
            background: "#FFFFFF !important",
            '&::placeholder': {
              fontSize: "14px !important",
              fontWeight: "400 !important",
              color: '#ABB5BE',
            }
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            fontSize: "14px !important",
            fontFamily: "Open Sans !important",
            fontWeight: 600,
            // border: "1px solid #EFF0F4",
            // borderRadius: "8px !important",
            // padding: "10px 2.5rem 10px 0px !important",
            // padding: "0 !important",

            '& input': {
              // padding: "0 0 0 8px !important",
              // border: "0 !important",

            },
            '& fieldset': {
              // borderRadius: "8px !important",
              // border: "1px solid #EFF0F4",
              // border: "none",
            },
            '&:hover fieldset': {
              // border: "none",
              // border: "1px solid #EFF0F4 !important",
            },
            '&.Mui-focused fieldset': {
              // border: "none",
              // border: "1px solid #EFF0F4 !important",
            },
          }, '& .Mui-disabled': {
            background: "#EFF0F4 !important",
            borderRadius: "8px !important",
          },
        },
        option: {
          fontSize: "14px !important",
          fontFamily: "Open Sans !important",
          color: "#68717A !important",
          // overflowX: "auto",
          // display: "block",
          // textOverflow: "ellipsis",
          // whiteSpace: "nowrap",
        },
        tag: {
          fontSize: "14px !important",
          fontFamily: "Open Sans !important",
          color: "#68717A !important",
          border: "1px solid #EFF0F4",
          backgroundColor: "#f9f9f9",
          fontWeight: 400,
          margin: "1px 3px",
          height: "auto",
          padding: 0,
          "& .MuiChip-label": {
            padding: "3px 8px",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
      },
      styleOverrides: {
        select: {
          fontSize: "14px !important",
          fontFamily: "Open Sans !important",
          fontWeight: 600,
          '& .Mui-disabled': {
            background: "#EFF0F4 !important",
            borderRadius: "8px !important",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px !important",
          fontFamily: "Open Sans !important",
          color: "#68717A !important",
        }
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: "10px !important",
          fontFamily: "Open Sans !important",
          fontSize: "14px !important",
          fontWeight: "400 !important",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginBottom: "10px !important",
          fontFamily: "Open Sans !important",
          fontSize: "14px !important",
          fontWeight: "400 !important",
          color: "#68717A !important",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: '#DC3545'
          }
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#152536',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        // root: {},
        label: {
          fontFamily: "Open Sans",
        },
      },
    },

    /** Switch */
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 43,
          height: 25,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#D2F4EA',
              '& + .MuiSwitch-track': {
                backgroundColor: '#20C997',
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: '#E9E9EA',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.3,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 21,
            height: 21,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#ADB5BD',
            opacity: 1,
            // transition: theme.transitions.create(['background-color'], {
            //   duration: 500,
            // }),
          },
        }
      },
    },

    /** Button */
    MuiButton: {
      defaultProps: {
        size: 'large',
      },
      styleOverrides: {
        contained: {
          "&:hover": {
            backgroundColor: '#262738',
          },
        },
        root: {
          fontFamily: "Open Sans",
          borderRadius: 8,
          textTransform: 'none',
          lineHeight: 'normal',
          boxShadow: 'none'
        },
        sizeSmall: {
          fontSize: '12px',
        },
        sizeMedium: {
          fontSize: '14px',
        },
        sizeLarge: {
          fontSize: '16px',
        }
      },
    },

    /** Link */
    MuiLink: {
      defaultProps: {
        variant: 'body1',
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: '#0071A9',
          '&:hover': {
            color: '#005F7F',
            fontWeight: '700'
          },
        },
      },
    },
    /** MuiDialog */
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {},
        paper: {
          '@media (max-width: 600px)': {
            margin: '10px',
            width: 'calc(100% - 20px)',
            '&.MuiDialog-paperScrollBody': {
              maxWidth: 'calc(100% - 20px)'
            }
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: "Open Sans",
          fontSize: 22,
          textAlign: "center",
          padding: '16px 10px 16px 10px',
          '@media (max-width: 600px)': {
            // textAlign: "left",
            fontSize: 16,
            padding: '10px 10px 10px 10px',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          fontFamily: "Open Sans",
          paddingTop: '0',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingBottom: '20px',
          textAlign: "center",
        },
      },
    },

    /** Table */
    MuiTable: {
      styleOverrides: {
        root: {
          fontFamily: "Open Sans",
          background: '#FFFFFF'
        },
      },

    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: "1px solid #EFF0F4",
          // border: "1px solid #ff0065",
          fontFamily: "Open Sans",
          borderRadius: 8,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: '#EFF0F4',
          // border: "1px solid #EFF0F4",
          // border: "1px solid #1341f9",
          padding: '20px',
          border: 'none',
          fontFamily: "Open Sans",
        },
        head: {
          color: '#68717A',
          fontSize: '12px',
          fontWeight: 600,
          fontFamily: "Open Sans",
        }
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #EFF0F4"
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #EFF0F4"
        },
      },
    },

    /** MuiAccordion */
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '20px'
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            minHeight: 'auto'
          }
        },
        content: {
          margin: '20px 0',
        },
        expandIconWrapper: {
          paddingLeft: '9px',
          paddingRight: '9px'
        }
      },
    },

    /** Progress Line */
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '8px',
          borderRadius: '8px',
          backgroundColor: '#E9ECEF',
          "&.MuiLinearProgress-colorSuccess": {
            backgroundColor: '#E9ECEF',
          },
        },
        bar: {
          "&.MuiLinearProgress-barColorSuccess": {
            backgroundColor: '#28A745',
          },
          "&.MuiLinearProgress-barColorWarning": {
            backgroundColor: '#E99940',
          },
          "&.MuiLinearProgress-barColorError": {
            backgroundColor: '#DF6438',
          },
        },
      },
    },

    /** Skeleton */
    MuiSkeleton: {
      styleOverrides: {
        root: {}
      }
    },

    /** Popover */
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: '#FFFFFF',
          boxShadow: 'none !important',
          border: '1px solid #EFF0F4 !important',
        },
      },
    },
  },
});
