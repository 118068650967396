import {
    Box,
    Divider,
    Typography
} from '@mui/material';
import React from 'react';
import Hobbies from '../../components/Hobbies';
import YourLanguage from '../../components/YourLanguage';
import SocialHandles from '../../components/SocialHandles';
import UserDigitalIntro from '../../components/UserDigitalIntro';
import WorkAndEduDesign from '../../components/WorkAndEduDesign';
import TextAndLineSection from '../../components/TextAndLineSection';
import TextAndChipSection from '../../components/TextAndChipSection';
import { dummySocialData, dummyTopics, dummyWorkAndEduData } from '../../../../utils/dummyLoadingData';

interface MentorDetailsProps {
    data?: any;
    mentorId: string;
}

const MentorDetails: React.FC<MentorDetailsProps> = ({ data = {}, mentorId }) => {
    const workAndEdu = dummyWorkAndEduData;
    const topicsAsStrings = dummyTopics.map((item) => (typeof item === 'string' ? item : item.label));
    return (
        <Box p={2} pb={0} borderRadius="8px" bgcolor="#FFFFFF" border="1px solid #EFF0F4">
            {/* Image / Video Section */}
            <UserDigitalIntro data={data} py={0} />

            {/* Bio Section */}
            <TextAndLineSection heading="Bio" data={data.bio || 'No bio available.'} />
            <Divider />

            {/* "I Can Help You With" Section */}
            <TextAndChipSection
                chipType="outline"
                param="IcanHelpYouWith"
                heading="I can help you with:"
                data={topicsAsStrings}
            />
            <Divider />

            {/* Work Experience Section */}
            <Box py={2}>
                <Typography variant="h5">Work Experience</Typography>
                <Box pt={2}>
                    {workAndEdu.length > 0 ? (
                        <WorkAndEduDesign
                            data={workAndEdu}
                            itemType="work"
                            wherePrint="communityUserProfile"
                            onEdit={() => { }}
                        />
                    ) : (
                        <Typography>No Work Experience</Typography>
                    )}
                </Box>
            </Box>
            <Divider />

            {/* Education Section */}
            <Box py={2}>
                <Typography variant="h5">Education and Skills</Typography>
                <Box pt={2}>
                    {workAndEdu.length > 0 ? (
                        <WorkAndEduDesign
                            data={workAndEdu}
                            itemType="education"
                            wherePrint="communityUserProfile"
                            onEdit={() => { }}
                        />
                    ) : (
                        <Typography>No Education</Typography>
                    )}
                </Box>
            </Box>
            <Divider />

            {/* Hobbies Section */}
            <Hobbies hobbiesValue={topicsAsStrings} />
            <Divider />

            {/* Languages Section */}
            <YourLanguage />
            <Divider />

            {/* Social Handles Section */}
            <SocialHandles socialData={dummySocialData} />
            <Divider />

            {/* Prior Dosen program experience */}
            <TextAndChipSection
                chipType='fill-blue'
                data={topicsAsStrings}
                heading="Prior Dosen program experience"
            />
        </Box>
    );
};

export default MentorDetails;
