import { useState, useEffect } from 'react';
import { Box, Grid, Switch, Button, TextField, Typography, IconButton, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { API } from '../../../../../../../api';
import { lockIcon } from '../../../../../components/common';
interface LearningObjective {
  _id: string;
  title: string;
  content: string;
}
interface LearningObjectsSettingProps {
  locked?: boolean;
  control: any;
  setLearningObjectivesData: (data: { display: boolean; number: number; objectives: string[] }) => void;
  setLearningObjectiveUpdateFlag: any;
  model: any;
}

const LearningObjectsSetting: React.FC<LearningObjectsSettingProps> = ({
  locked = false,
  control,
  setLearningObjectivesData,
  setLearningObjectiveUpdateFlag,
  model = ''
}) => {
  const lockOpacity = locked ? 'disabled-box-opacity-25' : '';
  const [allowCustomLearningObjects, setAllowCustomLearningObjects] = useState(false);
  const [learningObjectives, setLearningObjectives] = useState<LearningObjective[]>([]);
  const [customLearningObjectiveCount, setCustomLearningObjectiveCount] = useState<number>(4);
  const grpId = useParams().id || '';
  const orgId = useParams().orgId || '';
  const isMentorFieldLocked = model === 'masterclass' || model === 'masterclass&mentorship';

  const fetchLearningObjectives = async () => {
    try {
      const response = await API.getLearningObjectives({ grpId, orgId });
      if (response.status === 200) {
        const { display, number, objectives } = response.data.data.learningObjectives || {};
        setAllowCustomLearningObjects(display ?? false);
        setCustomLearningObjectiveCount(number ?? 4);
        setLearningObjectives(
          (objectives ?? []).map((obj: string, index: number) => ({
            _id: `id-${index + 1}`,
            title: `Learning Objective Option ${index + 1}`,
            content: obj
          }))
        );
      }
    } catch (error) {
      console.error('Error fetching learning objectives:', error);
    }
  };

  useEffect(() => {
    setLearningObjectivesData({
      display: allowCustomLearningObjects,
      number: customLearningObjectiveCount,
      objectives: learningObjectives.map((obj) => obj.content)
    });
  }, [allowCustomLearningObjects, customLearningObjectiveCount, learningObjectives, setLearningObjectivesData]);

  useEffect(() => {
    fetchLearningObjectives();
  }, []);
  // Handle adding a new learning objective
  const addLearningObjective = () => {
    if (allowCustomLearningObjects) {
      const newIndex = learningObjectives.length + 1;
      const newObjective = {
        _id: `id-${newIndex}`,
        title: `Learning Objective ${newIndex}`,
        content: ''
      };
      setLearningObjectives((prev) => [...prev, newObjective]);
    }
  };
  // Handle removing a learning objective by id
  const handleRemoveRow = (idToRemove?: string) => {
    const updatedObjectives = learningObjectives.filter(({ _id }) => _id !== idToRemove);
    // Renumber the remaining learning objectives after deletion
    const renumberedObjectives = updatedObjectives.map((obj, index) => ({
      ...obj,
      _id: `id-${index + 1}`,
      title: `Learning Objective ${index + 1}`
    }));

    setLearningObjectives(renumberedObjectives);
    setLearningObjectiveUpdateFlag(true);
  };

  return (
    <Box>
      {/* Title */}
      <Box display="flex" gap={0.5} alignItems="center" mb={2}>
        <Typography variant="h5">
          <span className={lockOpacity}>Learning Objectives</span>{' '}
          {isMentorFieldLocked && lockIcon({ title: 'This is not needed for the Masterclass model' })}
        </Typography>
      </Box>

      <Grid container spacing={2} className={lockOpacity} mb={2}>
  {/* Radio buttons */}
  <Grid item xs={12} display="flex" alignItems="center" gap="8px">
  <FormControl component="fieldset" disabled={isMentorFieldLocked}>
    <RadioGroup
      row
      name="learningObjects"
      value={allowCustomLearningObjects ? "true" : "false"}
      onChange={(e) => {
        const selectedValue = e.target.value === "true";
        setAllowCustomLearningObjects(selectedValue);
        setLearningObjectiveUpdateFlag(true)
        if (!selectedValue) {
          setLearningObjectives([]); 
          setLearningObjectivesData({
            display: false,
            number: customLearningObjectiveCount,
            objectives: [] 
          });
        } else {
          setLearningObjectivesData({
            display: true,
            number: customLearningObjectiveCount,
            objectives: learningObjectives.map((obj) => obj.content)
          });
        }
      }}
    >
      <FormControlLabel
        value="true"
        control={<Radio />}
        label="Require members to select their Learning Objectives from a list of options that you create"
      />
      <FormControlLabel
        value="false"
        control={<Radio />}
        label="Allow members to create their own Learning Objectives from scratch"
      />
    </RadioGroup>
  </FormControl>
</Grid>


        {/* Render Controller for custom learning objective */}
        <Grid item xs={12} container spacing={1} alignItems={'center'} >
          <Grid item xs={9} md={6} lg={5} xl={6} >
            <Typography>How many Learning Objectivs must mentees achieve as part of this program? </Typography>
          </Grid>
          {/* Custom learning objective input field */}
          <Grid item xs={3} md={2} lg={1}>
            <Controller
              control={control}
              name="customLearningObjective"
              defaultValue={customLearningObjectiveCount.toString()}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  type="number"
                  placeholder="4"
                  value={customLearningObjectiveCount}
                  onChange={(e) => {
                    const count = parseInt(e.target.value, 10);
                    setCustomLearningObjectiveCount(count);
                    setLearningObjectiveUpdateFlag(true);
                    onChange(count);
                  }}
                  disabled={locked}
                  
                />
              )}
            />
          </Grid>
        </Grid>
        </Grid>

      {/* Learning objectives */}
      <Grid container spacing={2}>
        {learningObjectives.map(({ _id, title, content }, index) => (
          <Grid item xs={12} key={_id}>
            <Grid container spacing={1}>
              {/* Objective Level Label */}
              <Grid item xs={12}>
                <Typography>{title}</Typography>
              </Grid>
              {/* Input Field */}
              <Grid item xs={10} md={6} lg={5}>
                <TextField
                  fullWidth
                  placeholder={`Enter ${title}`}
                  value={content}
                  onChange={(e) => {
                    const updatedObjectives = [...learningObjectives];
                    updatedObjectives[index].content = e.target.value;
                    setLearningObjectives(updatedObjectives);
                    setLearningObjectiveUpdateFlag(true);
                  }}
                  disabled={isMentorFieldLocked || !allowCustomLearningObjects}         
                />
              </Grid>
              {/* Remove Link */}
              <Grid item xs={2}>
                <Button
                  className="fs-12"
                  color="error"
                  onClick={() => handleRemoveRow(_id)}
                  disabled={isMentorFieldLocked || !allowCustomLearningObjects}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>

      {/* Add New Learning Objective Button */}
      <Box mt={1}>
        {(() => {
          const isDisabled=!allowCustomLearningObjects
          return (
            <IconButton disabled={isDisabled} size="small" onClick={isDisabled ? undefined : addLearningObjective}>
              <AddCircleIcon fontSize={'large'} color={isDisabled ? 'disabled' : 'info'} />
            </IconButton>
          );
        })()}
      </Box>
    </Box>
  );
};

export default LearningObjectsSetting;
