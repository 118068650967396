/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Grid, Typography } from '@mui/material';
import { lockIcon } from '../../../../components/common';
import { Link, Navigate, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import AppLoader from '../../../../../../components/AppLoader';
import { breadCrumbsActions } from '../../../../../../redux/slices/breadcrumbs/breadcrumbsSlice';
import ROLES from '../../../../../../utils/roles';
import { fetchGroupDetails } from '../../../../../../redux/slices/group-details/groupDetails';
import { getUserDetails } from '../../../../../../utils/orgName';
import useResponsiveScreenSwitch from '../../../../../../utils/useResponsiveScreenSwitch';
import useGroupSettings from '../../../../../../hooks/useGroupSettigs';
import useOrgSettings from '../../../../../../hooks/useOrgSettings';
import { groupLoaderActions } from '../../../../../../redux/slices/groupLoaderSlice';
import useGroupDetails from '../../../../../../queries/useGroupDetails';
import useGroupList from '../../../../../../queries/useGroupList';
import ProgramTableLoader from '../../../../../../Loader/ProgramTableLoader';
import MySkeleton from '../../../../../../components/Skeleton';

interface TabWithLockIconProps {
  tabName: string;
  isActive: boolean;
  onClick: () => void;
  tabDisable?: boolean;
}

const ProgramDetails = () => {
  const orgGroupData = useAppSelector((state) => state.orgLevelGroup);
  const orgDetails = useAppSelector((state) => state.orgDetails);
  const allGroupData = useAppSelector((state) => state.allGroups);
  const mainGroup = useAppSelector((state) => state.allGroups.data?.mainGroup);
  const breadcrumbs = useAppSelector((state) => state.breadCrumbs.breadcrumbs);
  const isGoalUploaded = useAppSelector((state) => state.groupDetails.data?.goalPublished);
  const dispatch = useAppDispatch();
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  const grpId = useParams().id || '';
  const orgId = useParams().orgId || '';
  const coId = useParams().coId || '';
  const [searchParams] = useSearchParams();
  const role = localStorage.getItem('role');
  let orgName = searchParams.get('org') || '';
  const groupLoader = useAppSelector((state) => state.groupLoader.loading);
  const [groupType, setGroupType] = useState('');

  const currentPath = useLocation();
  let { location } = getUserDetails();
  let param = '';
  if (role === ROLES.platAdmin) {
    param = `/?org=${orgName}`;
  }
  const groupDetails = useAppSelector((state) => state.groupDetails);
  const groupRoleList = useAppSelector((state) => state.groupRoleList);
  const groupSettings = useGroupSettings();
  const isMasterclassLocked = groupDetails.data?.model === 'masterclass';


  const { refetch } = useGroupDetails(grpId, location);
  const { refetch: fetchGroups } = useGroupList({ orgId: coId, grpId, location });

  useEffect(() => {
    if (allGroupData.data) {
      if (mainGroup) {
        const mainGroup = breadcrumbs.find((each) => each.name === 'Program');
        dispatch(breadCrumbsActions.removeBreadCrumbs(mainGroup));
      } else {
        dispatch(
          breadCrumbsActions.updateBreadCrumbs({
            id: grpId,
            name: allGroupData.data.mainGroupName,
            url: `/app/programdetails/${orgId}/${coId}/${grpId}/overview${param}`
          })
        );
      }
    }
  }, [mainGroup, allGroupData.data]);

  useEffect(() => {
    if (groupDetails?.data) {
      setGroupType(groupDetails?.data?.type);
    }
  }, [groupDetails?.data]);

  useEffect(() => {
    const newGrpId = grpId === 'admin' ? '' : grpId;
    fetchGroups();
    if (newGrpId) {
      refetch();
    }
    dispatch(groupLoaderActions.updateState(false));
  }, [grpId]);

  const activeTab = window.location.href
    .split('/')
    .find((each) => ['users', 'settings', 'insights', 'matches', 'overview'].includes(each));
  const url = `/app/programdetails/${orgId}/${coId}/${grpId}`;

  const navigate = useNavigate();
  const orgSettings = useOrgSettings();
  // const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);

  /** Loader */
  const programLoader =
    orgGroupData.loading ||
    allGroupData?.loading ||
    groupRoleList.loading ||
    orgDetails.loading ||
    groupDetails.loading;

  const skeletonLoader = () =>
    Array.from({ length: 4 }).map((_, index) => (
      <React.Fragment key={index}>
        <Grid item xs={12} md={6} lg={12 / 4}>
          <MySkeleton variant="rounded" height={245} />
        </Grid>
      </React.Fragment>
    ));

  const shouldRenderAppLoader = programLoader && groupType !== 'level';
  if (shouldRenderAppLoader) {
    return (
      <Box>
        <Grid container spacing={2} px={2} pt={2}>
          {skeletonLoader()}
        </Grid>
        <Box px={2}>
          <ProgramTableLoader />
        </Box>
      </Box>
    );
  }
  if (groupLoader) {
    return null;
  }

  if (orgGroupData.error) {
    return <Box>{orgGroupData.errorText}</Box>;
  }

  if (orgDetails.error) {
    return <Box>{orgDetails.errorText}</Box>;
  }

  /** Tabs design with all requrments */
  function createTabWithLockIcon({ tabName, isActive, onClick, tabDisable }: TabWithLockIconProps) {
    const TabDisableValue = groupType === 'level' ? (tabName !== 'Overview' ? true : false) : tabDisable;

    return (
      <Box
        pb={1}
        gap="3px"
        display="flex"
        alignItems="center"
        onClick={TabDisableValue ? undefined : onClick}
        borderBottom={isActive ? '3px solid #DF6438' : undefined}
        className={`cursor-${TabDisableValue ? 'not-allowed' : 'pointer'}`}
      >
        {TabDisableValue && lockIcon({})}
        <Typography
          lineHeight="normal"
          fontWeight={isActive ? '700' : '400'}
          color={isActive ? '#DF6438' : 'primary'}
          className={TabDisableValue ? 'disabled-box-opacity-25' : ''}
        >
          {tabName}
        </Typography>
      </Box>
    );
  }

  const getTabs = () => {
    if (!mainGroup) {
      return (
        <Box borderBottom="1px solid #EFF0F4" mb={2}>
          <Box display="flex" width="100%" overflow="auto">
            {breadcrumbs.map((each, index) => (
              <Fragment key={index}>
                {index === breadcrumbs.length - 1 ? (
                  <Box>
                    <Typography variant="caption" noWrap>
                      {each.name}
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Link to={each.url} onClick={() => dispatch(groupLoaderActions.updateState(true))}>
                      <Typography noWrap variant="caption" fontWeight="400" color="secondary">
                        {each.name}
                      </Typography>
                    </Link>
                    <Typography variant="caption" fontWeight="400" mx={0.5} color="secondary">
                      /
                    </Typography>
                  </Box>
                )}
              </Fragment>
            ))}
          </Box>

          {/* Group header with tabs */}
          <Box
            display={forMobile ? 'block' : 'flex'}
            alignItems="center"
            justifyContent="space-between"
            gap="8px"
            mt={1}
          >
            <Typography variant="h3" noWrap mb={1} mt={'8px'} lineHeight={'1.3'}>
              {_.get(allGroupData, 'data.mainGroupName', '')}
            </Typography>

            <Box display="flex" columnGap="40px">
              {/* Overview */}
              {createTabWithLockIcon({
                tabName: 'Overview',
                isActive: activeTab === 'overview',
                onClick: () => navigate(`${url}/overview${param}`),
                tabDisable: !(groupSettings && isGoalUploaded)
              })}
              {/* Insights */}
              {groupDetails.data?.type !== 'level' &&
                createTabWithLockIcon({
                  tabName: 'Insights',
                  isActive: activeTab === 'insights',
                  onClick: () => navigate(`${url}/insights${param}`),
                  tabDisable: !(groupSettings && isGoalUploaded)
                })}
              {groupDetails.data?.type !== 'level' &&
                createTabWithLockIcon({
                  tabName: 'Members',
                  isActive: activeTab === 'users',
                  onClick: () => navigate(`${url}/users${param}`),
                  tabDisable: false
                })}
              {/* Matches */}
              {groupDetails.data?.type !== 'level' &&
                !forMobile &&
                createTabWithLockIcon({
                  tabName: 'Matches',
                  isActive: activeTab === 'matches',
                  onClick: () => navigate(`${url}/matches${param}`),
                  tabDisable: isMasterclassLocked || !(groupSettings && isGoalUploaded),
                })}
              {/* Settings */}
              {groupDetails.data?.type !== 'level' &&
                !forMobile &&
                createTabWithLockIcon({
                  tabName: 'Settings',
                  isActive: activeTab === 'settings',
                  onClick: () => navigate(`${url}/settings${param}`)
                })}
            </Box>
          </Box>
        </Box>
      );
    }
    return null;
  };
  const checkSettings = () => {
    if (groupType === 'level') {
      return <Outlet />;
    }

    if (
      !mainGroup &&
      (!groupSettings || !isGoalUploaded) &&
      !currentPath.pathname.includes('/settings') &&
      orgSettings &&
      !currentPath.pathname.includes('/users')
    ) {
      return <Navigate to={`/app/programdetails/${orgId}/${coId}/${grpId}/settings`} />;
    }
    return <Outlet />;
  };

  if (orgGroupData) {
    if (allGroupData.error) {
      return <Box>{allGroupData.errorText}</Box>;
    }
    if (allGroupData.data && orgDetails.data) {
      return (
        <Box className="bodyBox">
          {allGroupData.data ? getTabs() : null}
          {checkSettings()}
        </Box>
      );
    }
  }

  return null;
};

export default ProgramDetails;
