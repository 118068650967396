import React, { useState } from "react";
import {
    Box,
    Link,
    Divider,
    Typography
} from "@mui/material";
import LeaderBoardCard from "./LeaderBoardCard";
import { useModule } from "../../../../../utils/useModule";
import MySkeleton from "../../../../../components/Skeleton";
import { CardStyle } from "../../../style-components/CardStyling";
import CompletedLeaderboardUsers from "./CompletedLeaderboardUsers";
import { generateDummyLeaderboardData } from "../../../../../utils/dummyLoadingData";

const Leaderboard: React.FC<{ data: any[]; loader?: boolean }> = ({ data = [], loader }) => {
    const classes = CardStyle();
    const { isHybrid, isMasterclass } = useModule();
    const [openDialog, setOpenDialog] = useState(false);
    const getData = loader ? generateDummyLeaderboardData(9) : data; // For Loader
    const leaderboardData = [...getData]
        .sort((a, b) => b.goalProgress - a.goalProgress)
        .filter((item) => item?.goalProgress < 100);

    // Dynamic chunk size
    const chunkSize = isHybrid ? 2 : 3;
    const chunkedData = Array.from({ length: Math.ceil(leaderboardData.length / chunkSize) }, (_, i) =>
        leaderboardData.slice(i * chunkSize, i * chunkSize + chunkSize)
    );

    /** View Completed popup open */
    const handleDialogOpen = (open: boolean) => { setOpenDialog(open) };
const cardWidth = isMasterclass?'250px':'200px';
    return (
        <>
            <Box className={isHybrid ? '' : classes.cardBox}>
                {/* Header Section */}
                <Box display="flex" justifyContent="space-between" p={isMasterclass ? 2:0}>
                    <Typography variant={isHybrid ? "h6" : "h5"} color={isHybrid ? "secondary" : ''}>
                        {loader ? <MySkeleton width={100} /> : "Leaderboard"}
                    </Typography>
                    <Link
                        variant="body1"
                        color="info.main"
                        onClick={() => handleDialogOpen(true)} // Open dialog
                    >
                        {loader ? <MySkeleton width={100} /> : "View Completed"}
                    </Link>
                </Box>

                {isHybrid ? '' : <Divider />}

                {/* Leaderboard Cards */}
                {chunkedData.length > 0 ? (
                    <Box overflow="auto" pt={2} pb={isMasterclass ? 1: 0} px={isMasterclass ? 2 :0}>
                        <Box display="flex" gap={2} overflow={"auto"} flexWrap="nowrap">
                            {chunkedData.map((row, index) => (
                                <Box
                                    mb={1}
                                    gap={1.5}
                                    key={index}
                                    display="flex"
                                    flexDirection="column"
                                    width={cardWidth}
                                    minWidth={cardWidth}
                                >
                                    {row.map((item) => (
                                        <LeaderBoardCard
                                            key={item?.id}
                                            mid={item?.mid}
                                            loader={loader}
                                            avgProgress={0}
                                            headshot={item?.headshot}
                                            displayName={item?.displayName}
                                            goalProgress={item?.goalProgress || 0}
                                        />
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ) : (
                    <Typography textAlign="center" py={{ xs: 2, md: 5 }}>
                        No Data Found
                    </Typography>
                )}
            </Box>

            {/* Dialog for View Completed */}
            <CompletedLeaderboardUsers
                loader={loader}
                open={openDialog}
                onClose={() => handleDialogOpen(false)}
                data={getData?.filter((item) => item.goalProgress === 100)}
            />
        </>
    );
};

export default Leaderboard;
