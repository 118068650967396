import React, { useState } from "react";
import _ from 'lodash';
import TagBox from "../../components/TagBox";
import ObjectiveModal from "./ObjectiveModal";
// import { useNavigate } from "react-router-dom";
import { useModule } from "../../../../utils/useModule";
import { getStatusColor } from "../../components/common";
import { Box, Typography, Button, Link } from "@mui/material";
import { DisabledVideoCall, ToDoIcon } from "../../../../utils/Icons";
import CheckableTickIcon from "../../../../components/CheckableTickIcon";

interface Task {
    id: string;
    title: string;
    noteCount?: number;
    description: string;
    videoCallCount?: number;
    objectiveNumber?: number;
    totalObjectives?: number;
    status: "todo" | "inprogress" | "completed";
}

const NewTaskCard: React.FC<{ task: Task }> = ({ task }) => {
    // const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);
    const { isMentorship } = useModule();
    const status = task?.status || "todo";
    const statusColor = getStatusColor(status);
    const noteCount = task?.noteCount ?? 0;
    const videoCount = task?.videoCallCount ?? 0;

    return (
        <>
            <Box
                key={task?.id}
                display="flex"
                className="taskCard"
                flexDirection="column"
                justifyContent="space-between"
            >
                <Box>
                    <Box
                        borderRadius="8px 8px 0 0"
                        borderTop={`10px solid ${statusColor}`}
                    />
                    <Box p={2}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography noWrap color="secondary" variant="h6">
                                {isMentorship
                                    ? `Objective ${task?.objectiveNumber} out of ${task?.totalObjectives}`
                                    : "<Level name>"}
                            </Typography>
                            {/* Show the check icon only for mentorship */}
                            {!isMentorship && <CheckableTickIcon />}
                        </Box>
                        <Typography
                            noWrap
                            variant="h5"
                            fontStyle={task?.title ? '' : "italic"}
                            color={task?.title ? statusColor : 'secondary.light'}
                        >
                            {isMentorship
                                ? task?.title || "Untitled"
                                : "<Lesson name>"}
                        </Typography>
                    </Box>

                    {/* Render TagBox and other elements only for mentorship */}
                    {isMentorship && (
                        <>
                            <TagBox
                                mb={2}
                                mt={1}
                                mx={'auto'}
                                fontSize="14px"
                                color={statusColor}
                                text={status === "todo" ? "To Do" : status === 'inprogress' ? 'In Progress' : 'Completed'}
                            />

                            <Box
                                gap={2}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography variant="subtitle2" color="secondary">
                                        {noteCount}
                                    </Typography>
                                    <ToDoIcon />
                                </Box>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography variant="subtitle2" color="secondary">
                                        {videoCount}
                                    </Typography>
                                    <DisabledVideoCall />
                                </Box>
                            </Box>
                        </>
                    )}

                    {/* Description section */}
                    <Typography
                        px={2}
                        pt={!isMentorship ? 0 : 2}
                        className={!isMentorship ? "sevenLineThenDots" : "threeLineThenDots"}
                    >
                        {/* {task?.description} */}
                        Learn how to implement strategies that support my business case and capture my target market.
                        Learn how to effectively manage your resources, improve processes, and achieve better results.
                        The journey to success starts here, with clear goals and the right tools.
                        Learn how to effectively manage your resources, improve processes, and achieve better results.
                        The journey to success starts here, with clear goals and the right tools.
                    </Typography>
                    {!isMentorship && (
                        <Link
                            pl={2}
                            variant="h6"
                            color="#152536"
                            onClick={() => console.log('View more link clicked')}
                        >
                            View more
                        </Link>
                    )}
                </Box>

                {/* Action Button */}
                <Box alignItems="flex-end" justifyContent="center" display="flex" p={2}>
                    <Button
                        variant="contained"
                        className="w-100 fs-14"
                        onClick={handleOpenModal}
                        aria-label={`View ${task?.status === "completed" ? "Details" : "Progress"}`}
                        sx={{
                            backgroundColor: task?.status === "completed" ? "#11895E" : "info.main",
                        }}
                    >
                        {isMentorship
                            ? task?.status === "completed"
                                ? "View"
                                : "Progress"
                            : task?.status === "completed"
                                ? "Lesson Complete"
                                : "Complete Lesson"}
                    </Button>
                </Box>
            </Box>

            {/* Objective Modal */}
            <ObjectiveModal
                open={isModalOpen}
                title="Objective Details"
                onClose={handleCloseModal}
                onSubmit={(data: { name: string; progress: number }) => {
                    console.log("Form Submitted:", data);
                    return Promise.resolve();
                }}
            />
        </>
    );
}

export default NewTaskCard;
